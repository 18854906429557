import { faCalendarDay, faChartLine, faGaugeHigh } from "@fortawesome/free-solid-svg-icons"
import { route } from "preact-router"
import { useRef } from "preact/hooks"
import { revoke } from "../../hooks/TokenHooks"
import Head from "../header/head"
import HeadButton from "../header/headbutton"
import style from "../header/style.css"
import Button from "../inputs/button/button"
import Sidebar from "../sidebar/sidebar"
import platformStyle from "./style.css"

export default ({children}) => {
    const sideBarRef = useRef(null)

    const containerClasses = sideBarRef.current?.isHide() ? [platformStyle.container, platformStyle.expand] : [platformStyle.container]
    return <>
        <Head menuToggleClick = {() => sideBarRef.current && sideBarRef.current.toggle() }>
        <div class = { style["header-buttons"] }>
            <HeadButton onclick = {() => { route("/") }} icon = { faGaugeHigh }> Dashboard </HeadButton>
            <HeadButton icon = { faCalendarDay }> Events </HeadButton>
            <HeadButton icon = { faChartLine }> Analytics </HeadButton>
        </div>
            <Button style={{gridColumn: -2}} onClick={() => {
                revoke()
            }}>Logout!</Button>
        </Head>
        <div class = { platformStyle.page }>
            <Sidebar ref={sideBarRef}/>

            <div class = { platformStyle.container }>
                {children}
            </div>
        </div>
    </>
}