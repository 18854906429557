import { del, get, post, updateToken } from "./helper"

export const homepage = groupId => get(`/app/event/by-status?stt=DRAFT&stt=CLOSED&group=${groupId}`, true)

export const credentials = groupId => get(`/app/credentials/${groupId}/get-all`, true)
export const getPlatforms = async() => get('/app/platforms/get-all', true)

export const createVenue = async d => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    const {data, code} = await post(`/app/create-event`, true, d, {headers})

    if (code > 400) throw {data, code}

    return {data, code}
}

export const deleteVenue = async id => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    const {data, code} = await del(`/app/event/${id}`, true, {headers})

    if (code > 400) throw {data, code}

    return {data, code}
}

export const switchAccount = async id => {
    const {data, code} = await post(`/group/${id}/change`, true).catch(err => {
        console.log(err)
    })

    if (code > 299) return;
    updateToken(data.token, false)
}

export const generateSsoKeys = async () => {
    const { data, code } = await post("/sso/saml/generate", true).catch(err => {
        console.log(err)
    })

    if (code > 299) return;
    return data
}