import { faCloud, faCogs } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "preact/hooks"
import { switchAccount } from "../../../integrations/platform.service"
import style from "./style.css"

export default ({groups = [], active = null, switchGroup = () => {}}) => {

    const [current, setCurrent] = useState(active)

    useEffect(() => {
        if (active === null) switchAcc(groups.length == 1 ? groups[0] : active)
    }, [])

    useEffect(() => switchAccount(current), [current])

    const switchAcc = (id) => {
        if (id == current) return;
        switchGroup(id)
        setCurrent(id)
    }

    return <div class={style.accountG}>
        <h1>Accounts</h1>
        <div>
            {groups.map(g => <Account onClick={switchAcc} active={g.id == current} {...g}/>)}
        </div>
    </div>
}

const Account = ({active = false, manager = false, name, id, onClick = () => {}}) => {

    const groupSelect = () => {
        if (active) return;
        onClick(id)
    }

    return <div class={style.accountHoldr}>
        <div onClick={groupSelect} class={style.account}>
            <FontAwesomeIcon icon = {faCloud}/>
            <span>{name}</span>
        </div>

        {
            //Add page to manage the account!
            manager ? <FontAwesomeIcon icon={faCogs}/> : null
        }
    </div>
}