import style from "./style.css"
import { Pbutton, SUCCESS } from "../inputs/button/button"
import { faCalendarCheck, faFolderPlus } from "@fortawesome/free-solid-svg-icons"
import { route } from "preact-router"
import { useEffect, useState } from "preact/hooks"
import { getUserData } from "../../helpers/AuthHelper"
import Accounts from "./folders/accounts"
import { groupHook } from "../../hooks/LocalStorageHooks"
import { readToken } from "../../integrations/helper"

export default function({initialHide = false}) {
    const [hide, setHide] = useState(initialHide)
    const [group, setGroup] = useState(null)
    const { data } = getUserData()

    this.toggle = () => setHide(!hide)
    this.isHide = () => hide

    useEffect(() => {
        const jwt = readToken()
        if (!jwt) return;

        if (jwt.data.customer.current) {
            setGroup(jwt.data.customer.current.id)
        }

    }, [])

    useEffect(() => {
        groupHook.set(group)
    }, [group])

    const placeholderClasses = [style.placeholder]
    const sidebarClasses = [style.sidebar]
    const updateGroup = (id) => setGroup(id)
    const groupId = groupHook.get()

    if(hide) {
        placeholderClasses.push("hide")
        sidebarClasses.push("hide")
    }

    return <>
        <div class = {placeholderClasses.join(" ")}></div>
        <div class = {sidebarClasses.join(" ")}>

        { groupId ? <>
            <Pbutton onClick = {() => route(`/group/${groupId}/venue/new`)} icon = { faCalendarCheck } type = {SUCCESS}>Create a New Event</Pbutton>
            <Pbutton icon = { faFolderPlus }>Create a folder</Pbutton>
        </> : null }

        <Accounts groups={data.groups} active={groupId} switchGroup={updateGroup}/>
    </div>
    </>
}   