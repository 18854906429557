export default class LocalStorageHooks {
    _field
    _channelName
    _channel
    channel = null
    _notify = () => {}
    notify = () => {
        console.warn("Channel not configured")
    }

    constructor(field, channelName = null) {
        this._field = field
        this._channelName = channelName

        if (channelName) {
            /* Start - Message Protocol */
            this._channel = () => new BroadcastChannel(this._channelName)
            this.channel = this._channel()

            this.notify = (type, data) => this.channel.postMessage({type, data, field})
            this._notify = this.notify
        }
    }

    listen = (cb) => this._channel().addEventListener("message", ({isTrusted, data}) => {
        if (!isTrusted) {
			console.warn("Untrusted Message!")
			return;
		}

        cb(data)
    })

    get = () => window.localStorage.getItem(this._field)

    set = value => {
        window.localStorage.setItem(this._field, value)
        this._notify("set", value)
    }

    update = value => {
        window.localStorage.setItem(this._field, value)
        this._notify("update", value)
    }

    revoke = () => {
        if (!get()) return;
        
        window.localStorage.removeItem(this._field)   
        this._notify("del", null)
    }
}

export const groupHook = new LocalStorageHooks("selectedGroup", "groupChange");