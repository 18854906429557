import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "../style.css"

const button = ({children, text, onClick, loading, submit=false, ...rest}) => {
    let isLoading = loading || false

    let classes = [styles.button]
    if (isLoading) {
        classes.push(styles.disabled)
        classes.push(styles.loading)
    }

    return <div class = {styles.centerBt}>
        <button type={ submit ? "submit" : "button" } class = { classes.join(' ') } onClick = {onClick} {...rest}>
            {isLoading ? <FontAwesomeIcon icon = {faSpinner} /> : null}
            {children || text}
        </button>
    </div>
}

export default button

export const RoundButton = ({children = null, icon = null, onClick, type = styles.default, submit = false, ...rest}) => 
    <button type={ submit ? "submit" : "button" } class = { [styles.allround, styles.pbutton, type, (icon ? styles.textLeft : null)].join(' ') } onClick = {onClick} {...rest}>
        {icon ? <FontAwesomeIcon icon = {icon} /> : null} {children}
    </button>
export const Pbutton = ({children = null, icon = null, onClick, type = styles.default, submit, ...rest}) => {
    return <button type={ submit ? "submit" : "button" } class = { [styles.pbutton, type, (icon ? styles.textLeft : null)].join(' ') } onClick = {onClick} {...rest}>
        {icon ? <FontAwesomeIcon icon = {icon} /> : null} {children}
    </button>
}

export const SUCCESS = styles.success
export const WARN = styles.warn
export const ERROR = styles.error