import style from "../style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Component } from "preact"

class ShowField extends Component {
    render({onClick}, {}) {
        const classes = ["highlight", "noselect", style["absolute-right"]]
        return <span class= {classes.join(" ")} onClick = {onClick}><FontAwesomeIcon class = { style.icon } icon={ faEye }/> Show</span>
    }
}

export default ShowField