import style from './style.css'
import { route } from 'preact-router';

export default ({children, registryLink}) => {
    const back = _ => route("/")
    const register = _ => route("/register")

    return <div class = { style.bg }>
        <div class = { style.grid }>
            <div class = { style.verticalbar }>
                <div class = { style.registerTopbar }>
                    { !registryLink ? (<a class = {style.backButton} onClick = {back}><img src = "/assets/images/polygon-1-1@1x.png" /></a>) : null }
                    { registryLink ? (<a class = {style.signUp} onClick = {register}>Need an account? Sign Up</a>) : null }
                </div>
                <div class = { style.form }>
                    <div class = { style.tkLogo }><img class = { style.tkLogoImg } src = "/assets/images/tk-logo.png"/></div>
                    <h1 class = { style.title }>{ registryLink ? "Login Into My Accounta" : "Sign up for your account" }</h1>
                    <div class = { style.formPadding }>{children}</div>
                </div>
            </div>
        </div>
    </div>
}
