import { set } from "../hooks/TokenHooks";
import request from "./helper";

class LoginService {

    static async auth(email, password) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        var raw = JSON.stringify({email, password});

        var requestOptions = {
            method: 'POST',
            headers,
            body: raw,
            redirect: 'follow',
            credentials: 'same-origin'
        };

        const {code, data} = await request("/login", requestOptions)

        if (code == 200) set(data.token)

        return {code, data}
    }

    static async register(email, password, jobTitle, firstName, lastName) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");

        var raw = JSON.stringify({email, password, jobTitle, firstName, lastName});

        var requestOptions = {
            method: 'POST',
            headers,
            body: raw,
            redirect: 'follow',
            credentials: 'same-origin'
        };

        const {code, data} = await request("/register", requestOptions)

        if (code == 200) set(data.token)

        return {code, data}
    }
}

export default LoginService