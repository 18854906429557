import { Authorization } from "../messagery/Chanels"

const request = async (path, requestOptions = {}) => {

    let body = requestOptions?.body

    if (requestOptions?.body && !(body instanceof FormData) && typeof body !== 'string') {
        body = JSON.stringify(body)
    }

    const options = {
        ...requestOptions,
        body
    }

    const response = await fetch(`${requestOptions.host || process.env.SERVICE_URL}${path}`, options)

    if (response.status == 401 & getTokenIfExists()) return revokeToken()

    return {
        code: response.status,
        data: getJsonOrNull(await response.text())
    }
}

export const get = async (path, auth = false, options = {}) => {
    const headers = new Headers()
    if (auth) headers.append("Authorization", getToken())

    return request(path, { ...options,
        method: "GET",
        headers,
        redirect: 'follow',
        credentials: 'same-origin'
    })
}

export const post = async (path, auth = false, body = null, options = {}) => {
    const headers = options.headers || new Headers()

    if (auth) headers.append("Authorization", getToken())

    return await request(path, { ...options,
        method: "POST",
        headers,
        redirect: 'follow',
        credentials: 'same-origin',
        body
    })
}

export const del = async (path, auth = false, body = null, options = {}) => {
    const headers = options.headers || new Headers()

    if (auth) headers.append("Authorization", getToken())

    return await request(path, { ...options,
        method: "DELETE",
        headers,
        redirect: 'follow',
        credentials: 'same-origin',
        body
    })
}

export const revokeToken = () => updateToken()

export const updateToken = (token = null, refresh = true) => {
    if (token == null) window.localStorage.removeItem("token")
    else window.localStorage.setItem("token", token)
    
    if (refresh)
        if (!token) Authorization().postMessage("revoke")
        else Authorization().postMessage("update")
}

const getToken = () => {
    const token  = window.localStorage.getItem("token")

    if (token == null) revokeToken()
    return token
}

const getTokenIfExists = () => {
    const token  = window.localStorage.getItem("token")
    return token
}

export const readToken = () => {
    const token  = getTokenIfExists()

    if (!token) return token

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return { data: JSON.parse(jsonPayload), token };

}

export const getOrDefault = (dt, err = () => {}) => dt == null || dt == undefined ? err() : dt

export const getJsonOrNull = (dt) => {
    try {
        return JSON.parse(dt)
    } catch(e) {
        return null
    }

}

export default request

export const createDownload = (file, name) => {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', name);
    aElement.href = URL.createObjectURL(file);
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
}