import Label from "./label";
import style from "../style.css"
import ShowField from "./showField";
import { useEffect, useImperativeHandle, useRef, useState } from "preact/hooks";
import { forwardRef } from "preact/compat"

export const Input = function({type, name, id, label, icon, placeholder, positioning = "list", onChange = null, min = null, max = null, required = false, validate = false}) {

    const [state, setSt] = useState({
        type,
        show: type == "password" ? false : true,
        id,
        name,
        placeholder: placeholder || label,
        onChange: onChange || (() => {}),
        error: false,
        reload: false
    })

    const setState = (dt = {}) => {
        setSt({...state, ...dt})
    }

    const ipt = useRef(null)
    const hid = useRef(null)
    
    this.onShowPassword = e => {
        e.preventDefault()
        setState({ show : !state.show })
    }

    let inputClasses = [style.input]

    const validateFn = (value) => {
        const dt = value || ipt.current.value
        if (required && dt == "") inputClasses = [style.input, style.errorInput]
        else inputClasses = [style.input]
    }

    if (validate) validateFn()

    let currentType = state.show && state.type == "password" ? "text" : state.type;

    const containerClasses = [style.relative]
    if (positioning == "line") containerClasses.push(style.line)

    const change = ev => {
        const { value } = ev.srcElement
        if ((min && value < min) || (max && value > max)) {
            ipt.current.value = null
        }
        hid.current.value = value
        onChange !== null ? onChange(ev) : null
        if (validate) validateFn(value)
        setState({reload: !state.reload})
    }

    return <div class = { containerClasses.join(" ") }>
        <input ref={hid} name = {name} id = {id || name} type = "hidden" />

        <Label required={required} text = {label}/>
        { icon ? <ShowField onClick = {this.onShowPassword}/> : null }
        <input ref={ipt} min={min} max={max} type = { currentType } class = { inputClasses.join(" ") } onChange = {change} placeholder = {state.placeholder}/>
    </div>
}

export const textInput = ({name, id, label, placeholder, positioning, onChange, ...rest}) =>  
    <Input name = {name} id = {id} label = {label} placeholder = {placeholder} positioning={positioning} onChange = {onChange} {...rest}/>
export const passwordInput = ({name, id, label, placeholder, positioning, onChange, ...rest}) => 
    <Input name = {name} id = {id} label = {label} type = "password" icon = {true} placeholder = {placeholder} onChange = {onChange} positioning={positioning} {...rest}/>

// V2 of my own components bc I've messed up with those above
// remove in the future
export const UserInput = forwardRef((props, ref) => {

    const {name, label, placeholder = "", min = 0, max = 0, rawChange, onChange, type = "text", required = false} = props

    const [_type, setType] = useState(type)
    const [value, setValue] = useState(null)
    const [validationSt, setValidation] = useState([true, null])

    const toggle = () => setType(type == _type ? "text" : type)

    useEffect(() => { 
        validate()
        onChange ? onChange(value) : null
    }, [value]) //trigger outside event

    const validate = (checkEmpty = false) => {
        let errMessage = null
        if (_type === "file") {
            if (value || !required) return setValidation([true, null])
            return setValidation([false, "File upload required"])
        }

        if (required && (min > 0 && (checkEmpty || value.length > 0) && value.length < min) || (max > 0 && value.length > max)) {
            if (min > 0 && max > 0) errMessage = `This field must have between ${min} and ${max}`
            else if (min > 0 && max == 0) errMessage = `This field requires minimum size of ${min}`
            else errMessage = `This field requires maximum size of ${max}`
            return setValidation([false, errMessage])
        }
        return setValidation([true, null])
    }

    const containerClasses = [style.relative, style.line]
    let inputClasses = [style.input]
    let labelExtras = []

    const [valid, msg] = validationSt
    if (!valid) {
        inputClasses.push(style.errorInput)
        labelExtras.push(style.errorInput)
    }

    useImperativeHandle(ref, () => ({
        validate,
        validationSt
    }))
        

    const inputOptions = {
        name,
        type: _type,
        class: inputClasses.join(" "),
        onChange: (ev) => {
            rawChange ? rawChange(ev) : null
            setValue(() => {
                return _type === "file" ? ev.target.files : ev.target.value
            })
        },
        placeholder
    }

    return <div class = { containerClasses.join(" ") }>
        <Label required={required} text = {label} classes={labelExtras}/>
        { type === "password" ? <ShowField onClick = {toggle}/> : null }
        <div style={{width: "100%"}}>
            <input {...inputOptions} />
            <span className={style.errorInput}>{msg}</span>
        </div>
    </div>
})

export const rowClasses = [style.relative, style.line].join(" ")
