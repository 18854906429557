import { route, Router } from 'preact-router';

import { useState } from 'preact/hooks'
import Layout from '../routes/login/layout';
import Platform from '../components/layout/platform'
import LoginForm from './login/login-form';
import RegisterForm from './login/register-form';
import { isAuthorized } from '../helpers/AuthHelper';
import { channel } from '../hooks/TokenHooks';
import Home from '../routes/home';
import CreateVenue from '../routes/create-venue';

const Authorized = () => <Platform>
	<Router>
		<Home path="/"/>
		<CreateVenue path="/group/:groupId/venue/new" />
	</Router>
</Platform>

const Unauthorized = () => {
	const [registry, setRegistry] = useState(false)

	const onChange = async e => setRegistry(e.url == "/")

	return <Layout registryLink={registry}>
		<Router {...{onChange}}>
			<LoginForm path="/"/>
			<RegisterForm path="/register"/>
		</Router>
	</Layout>
}

const App = () => {
	const [authorized, set] = useState(isAuthorized())
	const authChannel = channel()

	authChannel.addEventListener("message", ({isTrusted, data}) => {
		if (!isTrusted) {
			console.warn("Untrusted Message!")
			return;
		}

		if (data.type == "del" && data.field == "token" && authorized) {
			set(false)
			return route("/")
		}

		if (!authorized) set(true)
	})

	return authorized ? <Authorized/> : <Unauthorized/>
}

export default App;