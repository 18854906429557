import { Component } from 'preact';
import style from './style.css'

class Warning extends Component {
    state = {show: false, text: '', classes: [style.alertbody]}
    type = {
        "error": style.error,
        "success": style.success,
        "warn": style.warn
    }

    constructor(props) {
        super(props)
        if (this.type[props.type]) {
            let classes = this.state.classes
            classes.push(this.type[props.type])
            this.setState({classes: classes})
        }
    }

    render(p) {

        let state = {...this.state, ...p}

        let classes = [style.alertbody]

        if (this.type[state.type]) classes.push(this.type[state.type])
        if (!state.show) classes.push("hide")

        return <div class = {classes.join(' ')}>
            { state.children || state.text}
        </div>
    }
}

export const Error = ({text, show, ...rest}) => <Warning show = {show} text = {text} {...rest} type = "error"/>
export const Success = ({text, show, ...rest}) => <Warning show = {show} text = {text} {...rest} type = "success"/>
export const Warn = ({text, show, ...rest}) => <Warning show = {show} text = {text} {...rest} type = "warn"/>
