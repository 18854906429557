const TOKEN = "token"
const CHANNEL = "Authorization"

/* Start - Message Protocol */
export const channel = () => new BroadcastChannel(CHANNEL)

const localChannel = channel()

const notify = (type, token) => localChannel.postMessage({type, token, field: TOKEN})

/* End - Message Protocol */

export const get = () => window.localStorage.getItem(TOKEN)

export const set = token => {
    window.localStorage.setItem(TOKEN, token)
    notify("set", token)
}

export const update = token => {
    window.localStorage.setItem(TOKEN, token)
    notify("update", token)
}

export const revoke = () => {
    if (!get()) return;
    
    window.localStorage.removeItem(TOKEN)   
    notify("del", null)
}