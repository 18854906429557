import { get, revoke } from "../hooks/TokenHooks"

const revokeToken = () => {
    revoke()
    return false
}

export const isAuthorized = () => {
    const token = getUserData()
    if (!token) return false;

    return new Date(token.data.exp * 1000) > new Date() ? token : revokeToken();
}

export const getUserData = () => {
    const token = get()
    if (!token) return false;

    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return { data: JSON.parse(jsonPayload), token };
    } catch(e) {
        return false
    }
}