import { textInput as TextInput, Input, passwordInput as PasswordInput } from "../inputs/text/text-inputs"
import Button from "../inputs/button/button"
import { Component } from "preact";
import LoginService from "../../integrations/login.service";
import { Error } from "../warnings/Warning";
import { route } from "preact-router";

class RegisterForm extends Component {
    state = { values: {}, loading: false, error: { show: false, mesage: "" } };
    #service = LoginService

    constructor(props) {
        super(props)
    }

    onSubmit = e => {
        e.preventDefault()
        this.setState({ loading: true })

        const { srcElement } = e
        const d = {}
        const formData = new FormData(srcElement)
        for(let [key, dt] of formData.entries()) {
            d[key] = dt
        }

        const { email, password, jobTitle, firstName, lastName } = d
        this.#service.register(email, password, jobTitle, firstName, lastName).then(({code, data}) => {
            if (!data.token) return this.setState({
                loading: false, 
                error : {
                    show: true, 
                    mesage: data.message
                }})
            return route("/")
        })
    }

    onInput = e => {
        let {name, value} = e.target
        let change = {}

        change[name] = value
        this.setState({values: {...this.state.values, ...change}})
    }

    render(_, {}) {
        return (
            <form onSubmit = {this.onSubmit}>
                <Error show = {this.state.error.show} text = { this.state.error.show ? this.state.error.mesage : null } />
                <TextInput name = "firstName" label = "First Name" onChange = {this.onInput}/>
                <TextInput name = "lastName" label = "Last Name" onChange = {this.onInput}/>
                <Input name = "email" type = "email" label = "Email Address" onChange = {this.onInput}/>
                <TextInput name = "jobTitle" label = "Job Title" onChange = {this.onInput}/>
                <PasswordInput name = "password" label = "Password" onChange = {this.onInput}/>
                <Button submit={true} text = "Register" onClick = {this.onClick} loading = {this.state.loading}/>
            </form>
        )
    }
}

export default RegisterForm
