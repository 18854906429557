import { textInput as TextInput, Input, passwordInput as PasswordInput } from "../inputs/text/text-inputs"
import Button from "../inputs/button/button"
import { Component } from "preact";
import LoginService from "../../integrations/login.service";
import { Error } from "../warnings/Warning";
import { route } from "preact-router";

class LoginForm extends Component {
    state = { values: {}, loading: false, error: { show: false, mesage: "" } };
    #service = LoginService

    constructor(props) {
        super(props)
    }

    onSubmit = e => {
        e.preventDefault()
        this.setState({ loading: true })

        const { srcElement } = e
        const d = {}
        const formData = new FormData(srcElement)
        for(let [key, dt] of formData.entries()) {
            d[key] = dt
        }

        const { email, password } = d
        this.#service.auth(email, password).then(({data}) => {
            if (!data.token) return this.setState({loading: false, error : { show: true, mesage: data.message }})
            return route("/")
        })
    }

    render(_, {}) {
        
        return (
            <form onSubmit = {this.onSubmit}>
                <Error show = {this.state.error.show} text = { this.state.error.show ? this.state.error.mesage : null } />
                <Input name = "email" type = "email" label = "Email Address"/>
                <PasswordInput name = "password" label = "Password"/>
                <Button submit={true} text = "Login" onClick = {this.onClick} loading = {this.state.loading}/>
            </form>
        )
    }
}

export default LoginForm
